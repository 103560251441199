import React, {useCallback, useState} from "react";
import {Modal} from "antd";

export const DraggableModal = (props) => {
    const [position, setPosition] = useState({x: 1900/4, y: 100});
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({x: 0, y: 0});

    const {title, ...rest} = props;

    const handleMouseDown = useCallback((e) => {
        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    }, [position]);

    const handleMouseMove = useCallback((e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - dragStart.x,
                y: e.clientY - dragStart.y
            });
        }
    }, [isDragging, dragStart]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    React.useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    const draggableTitle = (
        <div
            style={{cursor: 'move'}}
            onMouseDown={handleMouseDown}
        >
            {title}
        </div>
    );

    return (
        <Modal
            title={draggableTitle}
            {...rest}
            style={{
                ...rest.style,
                position: 'fixed',
                top: `${position.y}px`,
                left: `${position.x}px`,
            }}
        />
    );
};