import {NotifyError, NotifyInfo} from "./notify";

export const api = {
    token: localStorage.getItem('token'),
    getJSON: function (url, data, options = {}, blob = false) {
        return api.requestJSON('GET', url, data, options, blob);
    },
    postJSON: function (url, data, options = {}, blob = false) {
        return api.requestJSON('POST', url, data, options, blob);
    },
    requestJSON: function (method, url, data, options = {}, blob = false) {
        options |= {notifyErrors: true};
        return new Promise(async (resolve, reject) => {
                try {
                    const params = {
                        method: method,
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + api.token
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(data),
                    }
                    let response = await fetch(url, params);
                    const status = response.status;
                    if (status > 300 && status !== 422) {
                        // throw response.statusText;
                        if (response.status === 401) {
                            Logout();
                        }
                        if (response.status === 403) {
                            NotifyError('Не хватает прав для выполнения операции');
                        } else {
                            if (options.notifyErrors) {
                                NotifyError(response.statusText);
                            } else {
                                const json = await response.json();
                                if (json) {
                                    reject(json);
                                    return;
                                } else {
                                    reject(response.statusText);
                                    return;
                                }
                            }
                        }
                    }
                    if (blob) {
                        let blob = await response.blob();
                        resolve(blob);
                        return;
                    }
                    if (response.headers.get('content-type') && response.headers.get('content-type').includes('application/json')) {
                        response = await response.json();
                    }
                    if (status === 422) {
                        reject(response);
                    } else {
                        resolve(response);
                    }
                } catch (e) {
                    console.log('ошибка запроса', e)
                    // NotifyError(e)
                    reject(e);
                }
            }
        );
    }
};

export const buildQuery = (params = {}) => Object.entries(params)
    .filter(([k, v]) => v !== undefined && v !== null && v !== '')
    .map(([k, v]) => Array.isArray(v) ? v.map(val => `${k}[]=${val}`).join('&') : `${k}=${v}`)
    .join('&');


export const Logout = function () {
    api.getJSON('/api/user/logout').then(() => {
        localStorage.removeItem('token');
        window.location.reload();
    })
}

export const format = (number, showZero = false) => {
    if (!showZero && (!number || isNaN(number))) {
        return '–';
    }
    if (showZero && isNaN(number)) {
        number = 0;
    }
    return new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number);
}

export const unformat = (number) => {
    if (typeof number === 'string') {
        return number.toString().replace(/\s/g, '').replace(/,/g, '.');
    }
    return number;
}

/**
 * Форматирование ФИО
 *
 * Примеры:
 *
 * formatFIO('Иванов Иван Иванович') => 'Иванов И.И.'
 * formatFIO('Иванов Иван') => 'Иванов И.'
 * formatFIO('Иванов') => 'Иванов'
 * formatFIO('') => '–'
 * @param fullname
 * @returns {*|string}
 */
export const formatFIO = (fullname) => {
    if (!fullname) {
        return '–';
    }
    const words = fullname.split(' ');
    if (words.length === 3) {
        return `${words[0]} ${words[1].charAt(0)}.${words[2].charAt(0)}.`;
    } else if (words.length === 2) {
        return `${words[0]} ${words[1].charAt(0)}.`;
    } else if (words.length === 1) {
        return words[0];
    } else {
        return fullname;
    }
}

export const makeFilelink = (file) => {
    return 'file:///' + file.replace(/\\/g, '/').replace(/ /g, '%20');
}

export const getCurrentSectionPath = () => {
    return `/${window.location.pathname.split('/')[1]}`;
}

// export const newCopyToClipboard = async (text, message="Текст скопирован в буфер обмена") => {
//     try {
//         await navigator.clipboard.writeText(text);
//         if (message) {
//             NotifyInfo(message);
//         }
//     } catch (err) {
//         console.error('Failed to copy text to clipboard:', err);
//     }
// };

export function copyToClipboard(text, message = "Текст скопирован в буфер обмена") {
    const textArea = document.createElement("textarea");
    const scrollY = window.scrollY;
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        if (message) {
            NotifyInfo(message);
        }
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    window.scrollTo(0, scrollY);
    document.body.removeChild(textArea);
}

export const getBearerAuthHeader = () => {
    return {'Authorization': 'Bearer ' + api.token};
}

export const ArrayUnique = (array, key) => {
    return array.filter((v, i, a) => a.findIndex(t => (t[key] === v[key])) === i);
}

export function arraysEqual(arr1, arr2) {
    let result = false;
    if (!arr1 && !arr2) {
        result = true;
    } else if (!arr1 || !arr2) {
        result = false;
    } else if (arr1.length !== arr2.length) {
        result = false;
    } else {
        result = JSON.stringify(arr1) === JSON.stringify(arr2);
    }
    return result;
}

export function cut(text, length) {
    if (!text) {
        return text;
    }
    if (text.length > length) {
        return <span title={text}>{text.substring(0, length) + '...'}</span>
    }
    return text;
}

export default api;
