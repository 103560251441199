import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Input, Modal, Row, Select, Space, Table, Typography} from "antd"
import PageHeader from "../../components/mock/PageHeader";
import {api, buildQuery, format} from "../../lib/util";
import {NotifyError, NotifyInfo} from "../../lib/notify";
import {Link} from "react-router-dom";
import {OligsToProduction} from "./OligsToProduction";
import dayjs from "dayjs";
import {PermissionsContext} from "../../lib/PermissionsContext";


const {Search} = Input;
const {Text} = Typography;
const {Option, OptGroup} = Select;

const PlanningList = () => {
    const [search, setSearch] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [items, setItems] = useState([]);
    const [types, setTypes] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [modalOpened, setModalOpened] = useState(false);
    const [showOnlyPlanned, setShowOnlyPlanned] = useState(false);
    const loaded = useRef(false);

    const formRef = useRef();

    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('production.edit');

    useEffect(() => {
        setLoading(true);
        if (!loaded.current) {
            loadTypes({});
            loaded.current = true;
        }
        load({pagination});
    }, [showOnlyPlanned, pageSize]);

    function getColumns() {
        return [
            {
                title: '№ заказа', dataIndex: 'index', key: 'index', width: 80,
                render: (val, record) => {
                    let numbers = record.orders.filter((item) => record.ngs === item.pivot.ngs)
                        .map((order) => order.order_number);
                    return numbers.map((number, index) => <Text key={index} id={index}>{number}<br/></Text>);
                }
            },
            {
                title: 'Клиент',
                key: 'owner',
                render: function (item, record) {
                    const listItems = record.orders
                        .filter((item) => record.ngs === item.pivot.ngs)
                        .map((item, index) => <Text key={index} id={index}>{item.user.organization}<br/></Text>);
                    return <div>{listItems}</div>
                },
                width: '20%',
            },
            {
                title: 'Дата заказа',
                dataIndex: 'orderDate',
                key: 'orderDate',
                render: function (text, record) {
                    if (!record.orders || record.orders.length === 0) {
                        return <span>&mdash;</span>;
                    }
                    const listItems = record.orders
                        .filter((item) => record.ngs === item.pivot.ngs)
                        .map((record, index) => <Text key={index} id={index}>
                            {dayjs(record.created_at).format('L')}<br/></Text>);
                    return <div>{listItems}</div>
                },
                sorter: true,
                width: 120,
            },
            {title: 'Название', dataIndex: 'name', key: 'name', sorter: true, width: 110,},
            {
                title: 'Последовательность',
                dataIndex: 'full_sequence',
                key: 'full_sequence',
                width: '22%',
                sorter: true,
                ellipsis: true
            },
            {title: '5\'', dataIndex: 'modification_5', key: 'modification_5', width: 100, sorter: true,},
            {title: '3\'', dataIndex: 'modification_3', key: 'modification_3', width: 100, sorter: true,},
            {title: 'Метка', dataIndex: 'inner', width: 100, sorter: true,},
            {title: 'Длина', dataIndex: 'length', key: 'length', width: 100, sorter: true,},
            {
                title: 'ngs', dataIndex: 'ngs', key: 'ngs', width: 50, sorter: true,
                render: (value) => {
                    return value ? 'Да' : 'Нет';
                }
            },
            {
                title: 'НМОЛЬ', dataIndex: 'nanomole', key: 'nanomole', width: 100,
                render: (nmol, record) => {
                    const nmol_val = nmol ? Math.round(nmol * 100) / 100 : '-';
                    return format(nmol_val, true);
                }
            },
            {
                title: 'Заказано', key: 'ngs_sum', dataIndex: 'ngs_sum', sorter: true,
                width: 100, render: v => format(v)
            },
            {
                title: 'В производстве', dataIndex: 'in_work', key: 'in_work', width: 120,
                sorter: true,
                render: (value, record) => {
                    const text = value ? 'в наличии' : 'отсутствуют';
                    return value ?
                        <Link target={'_blank'} to={`/portion/?select=${record.full_sequence}`}>
                            <Text type={"success"}>{text}</Text>
                        </Link> :
                        <Text type={"danger"}>{text}</Text>;
                }
            },
            {
                title: 'База остатков', dataIndex: 'portion_in_stock_sum', key: 'portions_in_stock_sum', width: 100,
                sorter: true,
                render: v => format(v)
            },
        ];
    }

    const loadTypes = (params = {}) => {
        setLoading(true);
        api.getJSON(`/api/oligonucleotide-type`).then((result) => {
            setTypes(result);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination.current - 1);
        load({
            page: pagination.current,
            search,
            filter_types: filterTypes,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const handleFilters = (values) => {
        setFilterTypes(values);
        load({
            search,
            filter_types: values,
        });
    }

    const handleSearch = (value) => {
        setSearch(value);
        load({
            search: value,
            filter_types: filterTypes,
        });
    }

    const load = (params = {}) => {
        if (showOnlyPlanned) {
            params = {...params, only_planned: showOnlyPlanned};
        }
        params = {...params, page: params.page || 0, pageSize: pageSize};
        const query = buildQuery(params);
        api.getJSON(`/api/planning?${query}`).then((result) => {
            const {data, meta} = result;
            data.forEach((item) => {
                item.is_planned = false;
                item.olig_name = item.name;
            });
            // удаляем дубликаты заказов
            data.forEach((item) => {
                item.orders = item.orders.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            });
            setItems(data);
            setTotal(meta.total);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const url = `/api/planning/run`;
        const result = await api.postJSON(url, values);
        setLoading(false);

        if (result.message && result.errors) {
            for (const [key, value] of Object.entries(result.errors)) {
                let keys = key.split('.');
                if (key === 'oligonucleotide') {
                    formRef.current.setFields([
                        {
                            name: 'oligonucleotide_wrapper',
                            errors: [value]
                        },
                    ]);
                    continue;
                }
                formRef.current.setFields([
                    {
                        name: (keys.length > 1) ? [keys[0], parseInt(keys[1]), keys[2]] : key,
                        errors: [value]
                    },
                ]);
            }
            scrollToError();
        } else {
            setModalOpened(false);
            load({
                search,
                filter_types: filterTypes,
            });
            NotifyInfo('Сохранено');
        }
    };

    const scrollToError = () => {
        setTimeout(() => {
            let el = document.querySelectorAll('.ant-form-item-explain-error, .table-row-error');
            if (el.length) {
                el[0].scrollIntoView({block: "center", behavior: "smooth"});
            }
        }, 500)
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const showModal = () => {
        setModalOpened(true);
    };

    const handleCancel = () => {
        setModalOpened(false);
    };

    const handleOnlyPlanned = (planned) => {
        setShowOnlyPlanned(planned);
        setSelectedRowKeys([]);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedItems = items.filter(function (item) {
        return selectedRowKeys.indexOf(item.key) !== -1;
    });

    const hasSelected = selectedRowKeys.length > 0;

    const switchStatus = async (planned) => {
        const ids = selectedItems.map(item => item.id);
        if (!ids.length) {
            NotifyError('Выберите пробирки');
            return;
        }
        const url = `/api/planning/switch-status/${planned ? '1' : '0'}`;
        await api.postJSON(url, {ids}).then(() => {
            planned ? NotifyInfo('Пробирки спланированы') : NotifyInfo('Планирование отменено');
            load({pagination});
        });
    }

    const getFilter = () => {
        return showOnlyPlanned ? <Button type={'primary'} size={'small'}
                                         onClick={() => switchStatus(false)}>Отменить планирование</Button> :
            <Button type={'primary'} size={'small'} onClick={() => switchStatus(true)}>Спланировать</Button>;
    }

    function getSelectedItems() {
        return selectedItems.map(item => {
            item.done = parseFloat(item.portion_is_done_sum);
            item.process = parseFloat(item.portion_in_work_sum);
            item.need = parseFloat(item.ngs_sum);
            if (item.to_production === undefined || item.to_production === '') {
                item.to_production = parseInt(item.need - item.done);
            }
            if (isNaN(item.to_production)) {
                item.to_production = parseInt(item.ngs_sum);
            }
            return item;
        });
    }

    return (
        <>
            <PageHeader
                title="Передача в производство"
            />
            <div style={{background: '#fff', padding: 24, minHeight: 280}}>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={20}>
                                <Space size={'small'}>
                                    <Search
                                        placeholder="поиск"
                                        onSearch={handleSearch}
                                        style={{width: '400px'}}
                                    />
                                    <Select
                                        mode="tags"
                                        placeholder="Типы"
                                        style={{width: '400px'}}
                                        onChange={handleFilters}
                                    >
                                        <OptGroup label="Тип">
                                            {types && types.length > 0 && types.map((item, iterator) => {
                                                if (item.type === 0) {
                                                    return <Option key={item.id} value={item.name}>{item.name}</Option>;
                                                }
                                            })}
                                        </OptGroup>
                                        <OptGroup label="Подтип">
                                            {types && types.length > 0 && types.map((item, iterator) => {
                                                if (item.type === 1) {
                                                    return <Option key={item.id} value={item.name}>{item.name}</Option>;
                                                }
                                            })}
                                        </OptGroup>
                                    </Select>
                                </Space>
                            </Col>
                            <Col span={4}>
                                {canEdit && <Button
                                    style={{float: 'right'}}
                                    type="primary"
                                    size={'small'}
                                    onClick={() => showModal()}
                                    disabled={!hasSelected}>
                                    Отправить в производство
                                </Button>}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col span={24}>
                                <Button.Group size={'small'}>
                                    <Button
                                        type={!showOnlyPlanned ? 'primary' : ''}
                                        onClick={() => handleOnlyPlanned(false)}
                                    >
                                        В ожидании
                                    </Button>
                                    <Button
                                        type={showOnlyPlanned ? 'primary' : ''}
                                        onClick={() => handleOnlyPlanned(true)}
                                    >
                                        Спланированные
                                    </Button>
                                </Button.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col span={24}>
                        {canEdit && getFilter()}
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col>
                        <Table rowSelection={rowSelection}
                               size={'small'}
                               columns={getColumns()}
                               rowKey="key"
                               dataSource={items}
                               pagination={{
                                   showSizeChanger: true,
                                   pageSizeOptions: ['50', '100', '250', '500', '1000', '2000', '3000'],
                                   defaultPageSize: 50,
                                   total: total,
                                   pageSize: pageSize,
                                   onChange: (page, pageSize) => {
                                       setPageSize(pageSize);
                                   }
                               }}
                               loading={loading}
                               onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                title="Отправить в производство"
                open={modalOpened}
                width="80%"
                closable={true}
                destroyOnClose={true}
                onCancel={handleCancel}
                footer={[]}
            >
                <OligsToProduction selectedItems={getSelectedItems()} handleSubmit={handleSubmit}
                                   handleCancel={handleCancel}/>
            </Modal>
        </>
    );
};

export default PlanningList;
