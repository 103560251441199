import StageManager from "./StageManager";
import {useContext, useEffect, useState} from "react";
import locale from 'antd/lib/locale/ru_RU';
import {getMassResultsColumns, mapDataFunction, operationMarkup as oms, operations as ops, useColumns,} from "./Shared";
import {Button, Col, ConfigProvider, DatePicker, Row, Select, Space} from "antd";
import dayjs from "dayjs";
import {PermissionsContext} from "../../lib/PermissionsContext";

export default function Defect() {

    const [stages, setStages] = useState([]);
    const [dateFilter, setDateFilter] = useState(null);
    const [stageFilter, setStageFilter] = useState(null);
    const {columns, filters} = useColumns();
    const restColumns = columns.concat(getMassResultsColumns());

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState();

    const [queryParams, setQueryParams] = useState({page: 1, pageSize: 50});

    const {hasPermission} = useContext(PermissionsContext);

    function customFilter(data) {
        if (dateFilter) {
            data = data.filter(item => {
                return dayjs(item.current_stage_date).isBetween(dateFilter[0], dateFilter[1].add(1, 'day'));
            });
        }
        if (stageFilter) {
            data = data.filter(item => item.previous_stage.id === stageFilter);
        }
        return data;
    }

    function getOperations() {
        const result = [];
        if (hasPermission('portion.dewaste')) {
            result.push([oms[ops.DEWASTE]]);
        }
        if (hasPermission('synth.re-synth')) {
            result.push([oms[ops.RESYNTHESIS]]);
        }
        return result;
    }

    function onLoad(data) {
        let result = {};
        data.data.forEach(item => {
            result[item.previous_stage.id] = {value: item.previous_stage.id, label: item.previous_stage.name}
        });
        result = Object.keys(result).map(key => result[key]);
        setStages(result);
    }

    function getFilters() {
        return (
            <Row>
                <Col span={12}>
                    <Space>
                        <label>Диапазон</label>
                        <ConfigProvider locale={locale}>
                            <DatePicker.RangePicker onChange={(value) => {
                                setDateFilter(value);
                            }} value={dateFilter} placeholder={["Начало", "Конец"]}
                            />
                        </ConfigProvider>
                        <label>Этап:</label>
                        <Select style={{width: 200}} onChange={(e) => {
                            setStageFilter(e)
                        }} options={stages} value={stageFilter}/>
                        <Button onClick={() => {
                            setStageFilter(null);
                            setDateFilter(null);
                        }}>Сбросить</Button>
                    </Space>
                </Col>
            </Row>
        );
    }

    const getQueryParams = () => {
        const params = {};
        params['page'] = page;
        params['pageSize'] = pageSize;
        return params;
    }

    useEffect(() => {
        setQueryParams(getQueryParams());
    }, [page, pageSize]);

    return <>
        <StageManager
            title={'Бракованные пробирки'}
            operationButtons={getOperations()}
            columns={restColumns}
            mapFucntion={(data) => {
                setTotal(data.meta.total);
                data = mapDataFunction(data.data);
                data.forEach(item => {
                    if (item['wasted_date']) {
                        item['current_stage_date'] = item['wasted_date'];
                    }
                });
                return data;
            }}
            queryParams={queryParams}
            filter={filters}
            onLoad={onLoad}
            url={'/api/portion/wasted'}
            extraFilter={getFilters}
            dataFilter={customFilter}
            pagination={{
                pageSizeOptions: ['50', '100', '250', '500', '1000', '2000', '3000'],
                showSizeChanger: true,
                pageSize: pageSize,
                total: total,
                defaultPageSize: pageSize,
                onChange: (page, pageSize) => {
                    setPageSize(pageSize);
                }
            }}
            onDataTableChange={(pagination, filters, sorter, extra) => {
                setPage(pagination.current);
            }}
            // здесь проверка прав проверяется выше в getOperations
            canEdit={true}
        />
    </>;

}
