import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button, Input, Form, Skeleton, message } from "antd";
import PageHeader from "../../components/mock/PageHeader";
import { SaveOutlined } from "@ant-design/icons";
import api, { getBearerAuthHeader } from "../../lib/util";
import { useParams, useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
};

const OligonucleotideEdit = () => {
    const [item, setItem] = useState({});
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await api.getJSON(`/api/oligonucleotid/edit/${id}`);
                setItem(result);
                form.setFieldsValue(result);
            } catch (error) {
                message.error('Ошибка при загрузке данных');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id, form]);

    const handleSubmit = useCallback(async (values) => {
        try {
            const response = await fetch(`/api/oligonucleotid/update/${id}`, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    ...getBearerAuthHeader(),
                }
            });

            const result = await response.json();

            if (result.errors) {
                Object.entries(result.errors).forEach(([key, value]) => {
                    const keys = key.split('.');
                    form.setFields([
                        {
                            name: keys.length > 1 ? [keys[0], parseInt(keys[1]), keys[2]] : key,
                            errors: [value]
                        },
                    ]);
                });
            } else {
                message.success('Олиг успешно обновлен');
                history.push("/oligs");
            }
        } catch (error) {
            message.error('Ошибка при обновлении олига');
        }
    }, [id, history, form]);

    return (
        <>
            <PageHeader
                title="Редактирование олигонуклеотида"
                showFavorite={false}
            />
            <Skeleton loading={loading}>
                <Form
                    form={form}
                    {...layout}
                    name="oligonucleotide_edit"
                    onFinish={handleSubmit}
                    style={{ background: '#fff', padding: 24, minHeight: 280 }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Название"
                                name="name"
                                rules={[{ required: true, message: 'Поле "Название" обязательно' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Последовательность"
                                name="sequence"
                                rules={[{ required: true, message: 'Поле "Последовательность" обязательно' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Комментарий"
                                name="comment"
                            >
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <Form.Item>
                                <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </>
    );
};

export default OligonucleotideEdit;