import React, {useContext, useEffect} from 'react';
import {Col, Form, Input, Row, Table} from "antd";
import {PermissionsContext} from "../lib/PermissionsContext";
import {DraggableModal} from "./DraggableModal";


const PermissionsModal = ({onClose, visible}) => {

    const {allPermissions, permissions, enablePermission, disablePermission} = useContext(PermissionsContext);
    const [checkedPermissions, setCheckedPermissions] = React.useState(permissions || []);
    const [filter, setFilter] = React.useState('');

    const columns = [
        {id: 'name', title: 'Наименование', dataIndex: 'name'},
        {id: 'code', title: 'Код', dataIndex: 'code'},
    ];

    const getFilteredData = () => {
        return allPermissions.filter(permission => {
            return permission.code.toLowerCase().includes(filter.toLowerCase()) || permission.name.toLowerCase().includes(filter.toLowerCase());
        });
    }

    useEffect(() => {
        setCheckedPermissions(permissions);
    }, [permissions]);

    return <DraggableModal open={visible}
                           onOk={onClose}
                           onCancel={onClose}
                           width={900}
                           cancelButtonProps={{style: {display: 'none'}}}
                           closable={false}
                           destroyOnClose={false}
                           mask={false}
                           title={'Разрешения'}
    >
        <Row>
            <Col span={24}>
                <Form.Item label="Фильтр">
                    <Input value={filter} onChange={e => setFilter(e.target.value)}/>
                </Form.Item>
            </Col>
        </Row>
        <Table dataSource={getFilteredData()} columns={columns}
               style={{height: 500}}
               pagination={false}
               scroll={{y: 450}}
               rowKey={'id'}
               rowSelection={{
                   type: 'checkbox',
                   onChange: (selectedRowKeys, selectedRows) => {
                       // определить какие разрешения были добавлены, а какие удалены
                       const addedPermissions = selectedRows.filter(
                           row => !checkedPermissions.find(permission => permission.id === row.id)
                       );
                       let removedPermissions = checkedPermissions.filter(
                           permission => !selectedRows.find(row => row.id === permission.id)
                       );
                       removedPermissions = removedPermissions.filter(permission => getFilteredData().find(row => row.id === permission.id));
                       setCheckedPermissions(selectedRows);
                       if (addedPermissions) {
                           addedPermissions.forEach(permission => enablePermission(permission.code));
                       }
                       if (removedPermissions) {
                           removedPermissions.forEach(permission => disablePermission(permission.code));
                       }
                   },
                   selectedRowKeys: checkedPermissions.map(permission => permission.id)
               }}
        />
    </DraggableModal>
}

export default PermissionsModal;
