import api from "../../lib/util";
import {notification} from "antd";
import {NotifyError} from "../../lib/notify";

const ServerOperations = {
    DEBLOCK: 1,
    REMOVE_FROM_CARRIER: 2,
    OE_MEASURING: 3,
    TO_MASS_SPECTR: 4,
    EVAPORATION: 5,
    SEATING: 6,
    CLICK: 7,
    ACYLATION: 8,
    CHROMATOGRAPHY_VZH: 9,
    DESALINATION: 10,
    GLENPACK: 11,
    DISSOLUTION_FILTRATION: 12,
    ELUTION: 13,
    PHORESIS: 14,
    MKL_MEASURING: 15,
    MASS_RESULTS: 16,
    CHROMATOGRAPHY_ION: 17,
    UNKNOWN_KOSTYL: 18,
    DETRITYLATION: 19,
    REMOVE_MALONATE: 20,
};

const Stages = {
    SYNTHESIS: 1,
    DEBLOCK: 2,
    CLICK: 3,
    ACYLATION: 4,
    ELECTROPHORESIS: 5,
    VZH: 6,
    ION_EXCHANGE: 7,
    CARTRIDGE: 8,
    FINAL_CONTROL: 9,
    MASS_SPECTROMETRY: 10,
    VZH_1: 13,
    VZH_2: 14,
    VZH_3: 15,
    VZH_4: 16,
    VZH_5: 17,
    VZH_6: 18,
    STOCK: 19,
    PACKAGING: 20,
};

export const stageNames = {
    [Stages.SYNTHESIS]: 'Синтез',
    [Stages.DEBLOCK]: 'Деблокирование',
    [Stages.CLICK]: 'Клик',
    [Stages.ACYLATION]: 'Ацилирование',
    [Stages.ELECTROPHORESIS]: 'Форез',
    [Stages.VZH]: 'Хроматографирование',
    [Stages.ION_EXCHANGE]: 'Ион-обмен',
    [Stages.CARTRIDGE]: 'Картридж',
    [Stages.FINAL_CONTROL]: 'Финальный контроль',
    [Stages.MASS_SPECTROMETRY]: 'Масс-спектрометрия',
    [Stages.STOCK]: 'Склад',
    [Stages.PACKAGING]: 'Упаковка',
}

export const operationNames = {
    [ServerOperations.DEBLOCK]: 'Деблокирование',
    [ServerOperations.REMOVE_FROM_CARRIER]: 'Отделение от носителя',
    [ServerOperations.OE_MEASURING]: 'Измерение OE',
    [ServerOperations.TO_MASS_SPECTR]: 'На масс-спектр',
    [ServerOperations.EVAPORATION]: 'Упаривание',
    [ServerOperations.SEATING]: 'Высаживание',
    [ServerOperations.CLICK]: 'Клик',
    [ServerOperations.ACYLATION]: 'Ацилирование',
    [ServerOperations.CHROMATOGRAPHY_VZH]: 'Хроматография (ВЖ)',
    [ServerOperations.DESALINATION]: 'Обессоливание',
    [ServerOperations.GLENPACK]: 'Картриджная очистка',
    [ServerOperations.DISSOLUTION_FILTRATION]: 'Растворение и фильтрация',
    [ServerOperations.ELUTION]: 'Элюция',
    [ServerOperations.PHORESIS]: 'Форез',
    [ServerOperations.MKL_MEASURING]: 'Измерение МКЛ',
    [ServerOperations.MASS_RESULTS]: 'Результаты масс-спектра',
    [ServerOperations.CHROMATOGRAPHY_ION]: 'Хроматография (Ион)',
    [ServerOperations.UNKNOWN_KOSTYL]: 'Передан аликвота на масс-спектрометрию',
    [ServerOperations.DETRITYLATION]: 'Детритилирование',
    [ServerOperations.REMOVE_MALONATE]: 'Снятие малоната',
}


const OperationsApi = {

    extractErrorMessages(result) {
        let defaultMessage = result.message || "Ошибка выполнения операции";
        let messages = [];
        for (let key in result.errors) {
            messages.push(result.errors[key]);
        }
        if (messages.length === 0) {
            messages.push(defaultMessage);
        }
        return messages.join("\n");
    },

    async doOperation(type, portions, params = {}, showMessages = true) {
        return new Promise(async (resolve, reject) => {
            const url = `/api/portion/add-operations/${type}`;
            try {
                let result = await api.postJSON(url, {portions, ...params});
                if (result.message && showMessages) {
                    NotifyError(result.message);
                }
                resolve(result);
            } catch (e) {
                let message = OperationsApi.extractErrorMessages(e);
                if (showMessages) {
                    notification.error({message});
                }
                reject(message);
            }
        });
    },
    async editOperation(operation_id, operation_to_portion_id, portions, params = {}, showMessages = true) {
        return new Promise(async (resolve, reject) => {
            const url = `/api/portion/update-operations/${operation_id}/${operation_to_portion_id}`;
            try {
                let result = await api.postJSON(url, {portions, ...params});
                if (result.message && showMessages) {
                    NotifyError(result.message);
                }
                resolve(result);
            } catch (e) {
                let message = OperationsApi.extractErrorMessages(e);
                if (showMessages) {
                    notification.error({message});
                }
                reject(message);
            }
        });
    },
    async deleteOperation(operation_id, operation_to_portion_id, queue_id, showMessages = true) {
        return new Promise(async (resolve, reject) => {
            const url = `/api/portion/delete-operation/${operation_id}/${operation_to_portion_id}/${queue_id}`;
            try {
                let result = await api.postJSON(url);
                if (result.message && showMessages) {
                    NotifyError(result.message);
                }
                resolve(result);
            } catch (e) {
                let message = OperationsApi.extractErrorMessages(e);
                if (showMessages) {
                    notification.error({message});
                }
                reject(message);
            }
        });
    },


}

export {ServerOperations, OperationsApi, Stages};
