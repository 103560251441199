import {Button, Col, Row, Table} from "antd";
import Search from "antd/es/input/Search";
import debounce from "lodash/debounce";
import {useEffect, useState} from "react";
import api from "../../lib/util";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import * as PropTypes from "prop-types";
import {StockBase} from "./StockBase";

StockBase.propTypes = {
    operations: PropTypes.any,
    columns: PropTypes.any
};
const PackageOrders = () => {

    const [data, setData] = useState();
    const [searchString, setSearchString] = useState();
    const [loading, setLoading] = useState(false);

    const getColumns = () => {
        let columns = [
            {title: '№', dataIndex: 'order_number', key: 'order_number', width: 30},
            {
                title: 'Дата создания', dataIndex: 'created_at', key: 'created_at', width: 70, render: (text) => {
                    return dayjs(text).format('LL')
                }
            },
            {
                title: 'Заказчик', dataIndex: 'customer', key: 'customer', width: 200, render: (text, record) => {
                    return record.user.organization;
                }
            },
            {title: 'Приоритет', dataIndex: 'priority_text', key: 'priority_text', width: 80},
            {
                title: 'Тип заказа', dataIndex: 'type', key: 'type', width: 80, render: (text, record) => {
                    return record.type?.name;
                }
            },
            {
                title: 'Расфасовано',
                dataIndex: 'fully_packaged',
                key: 'fully_packaged',
                width: 80,
                render: (text, record) => {
                    return `${record.fully_packaged} / ${record.oligonucleotids.length}`;
                }
            },
            {
                title: 'Действия',
                width: 100,
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <Link to={`/packaging/edit/${record.id}`}>
                        <Button size={'small'} style={{margin: 6}} type="primary" onClick={() => {
                        }}>Перейти к фасовке</Button>
                    </Link>
                ),
            }
        ];
        return columns;
    }

    const onSearch = (e) => {
        setSearchString(e.target.value);
    };

    const loadData = () => {
        let params = [];
        let url = '/api/assigment';
        if (searchString) {
            params.push('search=' + searchString);
        }
        params.push('status=2');
        if (params.length > 0) {
            url += '?' + params.join('&');
        }
        api.getJSON(url).then((response) => {
            setData(response.data);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        loadData();
    }, [searchString]);

    return <div>
        <div style={{background: '#fff', padding: '19px 24px', minHeight: 280}}>
            <Row style={{marginBottom: 20}}>
                <Col span={10}>
                    <Search size={'small'} placeholder="Поиск" onChange={debounce(onSearch, 500)}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table columns={getColumns()} size={'small'} dataSource={data} rowKey={'id'} bordered
                           loading={loading}/>
                </Col>
            </Row>
        </div>
    </div>
}

export default PackageOrders;
