import React from 'react';
import {useLocation, Redirect} from "react-router-dom";

function Default() {

    const router = useLocation();
    const current = router.pathname;
    return current === '/' ? <Redirect to='/main'/> : null;

}

export default Default;

