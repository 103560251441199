import {Button, Col, Form, Modal, Row, Skeleton, Space, Table, Upload} from 'antd';
import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import ajax, {api, getBearerAuthHeader} from "../../lib/util";
import {getOrderPriority} from "../Order/OrderEdit";
import {PrinterOutlined, UploadOutlined} from "@ant-design/icons";
import {NotifyError} from "../../lib/notify";
import {Resynth, SynthTypes} from "../Stages/Operations/Resynth";
import FormattedInput from "../../components/FormattedInput";
import {useColumns} from "../Stages/Shared";
import {PermissionsContext} from "../../lib/PermissionsContext";

const ShipmentView = (props) => {

    const {onClose, onOK} = props;
    const [form] = Form.useForm();
    const [selectedRows, setSelectedRows] = useState([]);
    const [rows, setRows] = useState(props.assigned);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [shipment, setShipment] = useState(false);
    const [loadLabels, setLoadLabels] = useState(false);
    const [showResynth, setShowResynth] = useState(false);

    const {columns} = useColumns(false);

    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('shipment.edit');

    const orderColumns = [
        {title: '№', dataIndex: 'order_number', key: 'order_number', width: 30},
        {
            title: 'Дата создания', dataIndex: 'created_at', key: 'created_at', width: 70, render: (text) => {
                return dayjs(text).format('LL')
            }
        },
        {title: 'Заказчик', dataIndex: 'organization', key: 'organization', width: 200},
        {
            title: 'Приоритет', dataIndex: 'priority', key: 'priority', width: 80, render: (value) => {
                return getOrderPriority(value);
            }
        },
        {title: 'Тип заказа', dataIndex: 'order_type', key: 'order_type', width: 80},
    ];

    function renderCell(name, value, record, fieldType = 'text') {
        function onChange(val) {
            if (!val) {
                val = 0;
            }
            const index = rows.indexOf(record);
            rows[index][name] = val;
            setRows(rows);
        }

        return <FormattedInput value={record[name]} onChange={onChange}/>
    }

    const getColumns = () => {
        let result = columns;
        const onlyColumns = [
            'date', 'device_name', 'place_name', 'scale_name', 'olig_name', 'olig_sequence', 'length', 'quantity', 'mkl', '260_280', '260_230', 'nanomole', 'e260', 'mw', 'mkg', 'operations'
        ];
        result = result.filter((column) => onlyColumns.includes(column.dataIndex));

        if (shipment.is_check) {
            result = result.concat([
                {
                    title: 'ОЕ сверки', dataIndex: 'oligonucleotid',
                    width: 100,
                    render: (value, record) => renderCell('check_oe', value, record)
                },
                {
                    title: 'Изменения',
                    dataIndex: 'check_percent',
                    ellipsis: true,
                    width: 100,
                    render: (value) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
                },
            ]);
        }
        return result;
    }

    function saveShipment() {
        let url = props.data.id ? `/api/shipment/update/oe/${props.data.id}` : '/api/shipment/store';
        let postData = {
            id: props.data.id,
            ...form.getFieldsValue(),
            portions: _rows.map((row) => {
                return {'row_id': row.id, 'check_oe': row.check_oe};
            }),
        }
        return new Promise((resolve, reject) => {
            ajax.postJSON(url, postData).then((response) => {
                if (onOK) {
                    onOK();
                }
                resolve();
            }).catch((error) => {
                console.log('save shipment error', error);
                reject();
            });
        });
    }

    function loadShipment(id) {
        ajax.getJSON(`/api/shipment/${id}`).then(async (response) => {
            form.setFieldsValue({
                ...response,
                passport_date: dayjs(response.passport_date),
                shipment_date: dayjs(response.shipment_date),
            });
            let portions = response.portions.map((item) => {

                const roundValue = 1000;
                const device_short_name = item.places[0]?.device_short_name;
                const device_cycle_name = item.places[0]?.device_cycle_name;
                const device_name = `${device_short_name || '-'} / ${device_cycle_name || '-'}`;
                const e260 = item.current_oligonucleotide?.e260 ? (Math.round(item.current_oligonucleotide.e260 * roundValue) / roundValue) : (item.oligonucleotid?.e260 ? Math.round(item.oligonucleotid?.e260 * roundValue) / roundValue : '-');
                const mw = item.current_oligonucleotide?.mw ? Math.round(item.current_oligonucleotide.mw * roundValue) / roundValue : (item.oligonucleotid?.mw ? Math.round(item.oligonucleotid?.mw * roundValue) / roundValue : '-');

                return {
                    ...item,
                    name: item.oligonucleotid.name,
                    full_sequence: item.oligonucleotid.full_sequence,
                    all_places: item.places,
                    types: item.oligonucleotid.types.map((type) => type.name).join(', '),
                    length: item.oligonucleotid.length,
                    olig_name: item.oligonucleotid.name,
                    place_name: (item.places[0]?.device_place_name || '-') + ' / ' + (item.places[0]?.device_place_position || '-'),
                    scale_name: (item.places[0]?.scale?.scale || '-') + ' ' + (item.places[0]?.scale?.dimension || '-'),
                    device_name,
                    e260,
                    mw,
                    current_sequence: item.current_sequence || '-',
                    work_sequence: item.current_oligonucleotide?.full_sequence || '-',
                    target_sequence: item.oligonucleotid?.full_sequence || '-',
                    type: item.oligonucleotid.types.map((type) => type.name).join(', '),
                    date: item.places[0].calendar.date,
                };
            });
            setRows(portions);
            setShipment(response);
        }).catch((error) => {
            console.log('load shipment error', error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (props.data.id) {
            setLoading(true);
            loadShipment(props.data.id);
        } else {
            form.resetFields();
        }
    }, []);

    function onSaveClick() {
        form.validateFields().then(() => {
            setSaving(true);
            saveShipment().then(() => {
                if (onClose) {
                    onClose(false);
                }
            }).finally(() => {
                setSaving(false);
            });
        });
    }

    // 1) если создание отгрузки, то показываем только то, что можно прикрепить (props.assigned)
    // 2) если редактирование - и то, что уже прикреплено (rows), и что еще можно прикрепить (props.assigned)
    const _rows = props.data.id ? [].concat(rows, props.assigned) : props.assigned;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function getSelectedRowKeys() {
        return selectedRows.map(item => item.id);
    }

    function labelPrintClick() {
        let ids = getSelectedRowKeys();
        if (ids.length === 0) {
            NotifyError('Выберите пробирки');
            return;
        }
        setLoadLabels(true);
        let url = '/api/portion/print/' + props.data.id;
        api.postJSON(url, {ids: ids}, {}, true).then(blob => {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob,
                {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            a.download = "labels.xlsx";
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
        }).finally(() => {
            setLoadLabels(false);
        });
    }

    function checkRows() {
        if (selectedRows.length === 0) {
            NotifyError('Выберите пробирки');
            return false;
        }
        return true;
    }

    function getRowForResynth() {
        return selectedRows.map((row) => {
            return {
                ...row,
                device_short_name: row.places[0].device_short_name,
                device_cycle_name: row.places[0].device_cycle_name,
                device_place_name: row.places[0].device_place_name,
                device_place_position: row.places[0].device_place_position,
                scale_name: `${row.places[0].device_scale} ${row.places[0].device_scale_dimension}`,
                olig_name: row.oligonucleotid.name,
                olig_sequence: row.oligonucleotid.full_sequence,
                types: row.oligonucleotid.types.map((type) => type.name).join(', '),
            };
        });
    }

    function onUploadFile(info) {
        if (info.file.status === 'done') {
            setLoading(true);
            loadShipment(props.data.id);
        }
    }

    return (
        <Modal open={true}
               destroyOnClose={true}
               title="Просмотр отгрузки"
               onCancel={() => onClose(true)}
               footer={[
                   <Button key="cancel" onClick={onClose}>Отмена</Button>,
                   canEdit ? <Button key="save" loading={saving} type="primary"
                                     onClick={onSaveClick}>Сохранить</Button> : null,
               ]}
               width={'100%'}>
            {loading && <Skeleton active={true}/>}
            {!loading && <Form form={form} onFinish={saveShipment} labelCol={{span: 12}} wrapperCol={{span: 7}}>
                <Row gutter={8} style={{marginTop: 20, marginBottom: 20}}>
                    <Col span={6}>
                        Номер паспорта: <strong>{props.data.passport_num}</strong>
                    </Col>
                    <Col span={18}>
                        Номер счета: <strong>{props.data.account_num}</strong>
                    </Col>
                    <Col span={6}>
                        Дата оформления паспорта: <strong>{dayjs(props.data.passport_date).format('DD.MM.YYYY')}
                    </strong>
                    </Col>
                    <Col span={6}>
                        Дата отгрузки: <strong>{dayjs(props.data.shipment_date).format('DD.MM.YYYY')}</strong>
                    </Col>
                </Row>
                <Table rowKey={'id'} size={'small'} dataSource={props.orderInfo} columns={orderColumns}
                       pagination={false}/>
                <Row gutter={8} style={{marginTop: 20, marginBottom: 20}}>
                    <Space>
                        {hasPermission('synth.re-synth') && <Button type={'primary'} onClick={() => {
                            if (checkRows()) {
                                setShowResynth(true);
                            }
                        }}>Досинтез</Button>}
                        <Button icon={<PrinterOutlined/>} loading={loadLabels}
                                onClick={labelPrintClick}>Этикетки</Button>
                        {shipment.is_check && canEdit &&
                            <Upload onChange={onUploadFile}
                                    action={"/api/shipment/" + props.data.id + "/upload"}
                                    headers={getBearerAuthHeader()}>
                                <Button icon={<UploadOutlined/>}>Загрузить паспорт</Button>
                            </Upload>}
                    </Space>
                </Row>
                <Table dataSource={_rows} columns={getColumns()}
                       size={'small'}
                       style={{marginTop: 10}}
                       scroll={{x: 1600}}
                       rowKey={'id'}
                       rowSelection={{
                           type: 'checkbox',
                           defaultSelectedRowKeys: [],
                           onChange: (selectedRowKeys, selectedRows) => {
                               setSelectedRows(selectedRows);
                           }
                       }}

                />
            </Form>}
            {showResynth && <Resynth data={getRowForResynth()} type={SynthTypes.EXTRA_SYNTH} onHide={() => {
                setShowResynth(false);
                props.onRefresh();
            }}/>}
        </Modal>
    );
};

export default ShipmentView;

