import {Badge, Button, Col, Modal, Row, Space, Table} from "antd";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {SplitPortions} from "../Stages/Operations/SplitPortions";
import {NotifyError} from "../../lib/notify";
import {JoinColumnsManager} from "../Stages/Operations/JoinColumnsManager";
import api from "../../lib/util";
import {getDefaultColumns, getMassResultsColumns, renderCurrentStage} from "../Stages/Shared";
import {Resynth, SynthTypes} from "../Stages/Operations/Resynth";
import {PermissionsContext} from "../../lib/PermissionsContext";

const OligPick = (props) => {

    const [showJoin, setShowJoin] = useState(false);
    const [showSplit, setShowSplit] = useState(false);

    const [showResynth, setShowResynth] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [process, setProcess] = useState(false);

    const [columnFilters, setColumnFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    const {hasPermission} = useContext(PermissionsContext);

    const getSelectedIds = () => {
        return selectedRows.map(item => item.id);
    }

    const assignToPackage = (id) => {
        if (checkRows()) {
            setProcess(true);
            const ids = getSelectedIds();
            api.postJSON(`/api/assigment/assign/${props.packageId}`, {ids}).then((response) => {
                props.onRefresh();
                setProcess(false);
            });
        }
    }

    const removeFromPackage = (id) => {
        if (checkRows()) {
            setProcess(true);
            const ids = getSelectedIds();
            api.postJSON(`/api/assigment/deassign/${props.packageId}`, {ids}).then((response) => {
                props.onRefresh();
                setProcess(false);
            });
        }
    }

    function checkRows() {
        if (selectedRows.length === 0) {
            NotifyError('Выберите пробирки');
            return false;
        }
        return true;
    }

    const modalProps = {
        open: true,
        onOk: props.onOk ? props.onOk : undefined,
        width: '100%',
        okText: 'Закрыть',
        cancelButtonProps: {style: {display: 'none'}},
        transitionName: 'none',
        maskTransitionName: 'none',
        onCancel: () => props.onOk(),
    }

    function getColumns() {
        let columns = getDefaultColumns(columnFilters, setColumnFilters, showFilters).concat(getMassResultsColumns());
        columns = columns.filter(column => column.dataIndex !== 'wasted_stage');

        columns.push({
                title: 'Прикреплено', render: (text, record) => {
                    return record.is_assigned ? <Badge status={'success'} text={'Да'}></Badge> :
                        <Badge text={'Нет'} status={'error'}></Badge>;
                }, fixed: 'right'
            }
        );
        const currentStageIndex = columns.findIndex((item) => item.dataIndex === 'previous_stage');
        columns.splice(currentStageIndex, 1, {
            title: 'Текущий этап', dataIndex: 'current_stage', key: 'current_stage', width: 100,
            render: (value) => {
                return renderCurrentStage(value);
            }
        });
        return columns;
    }

    return (
        <Modal {...modalProps}>
            <h3>Расфасовка пробирок</h3>
            <Row>
                <Col style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Space size={'small'} direction={'horizontal'}>
                        <Button type={'primary'} onClick={() => {
                            if (checkRows()) {
                                setShowJoin(true);
                            }
                        }}>Объединить</Button>
                        <Button type={'primary'} onClick={() => {
                            if (checkRows()) {
                                setShowSplit(true);
                            }
                        }}>Разъединить</Button>
                        {hasPermission('synth.re-synth') && <Button type={'primary'} onClick={() => {
                            if (checkRows()) {
                                setShowResynth(true);
                            }
                        }}>Досинтез</Button>}
                    </Space>
                    <Space size={'small'} direction={'horizontal'}>
                        <Button disabled={process} type={'primary'} onClick={() => {
                            if (checkRows()) {
                                assignToPackage();
                            }
                        }}>Прикрепить</Button>
                        <Button disabled={process} type={'primary'} onClick={() => {
                            if (checkRows()) {
                                removeFromPackage();
                            }
                        }}>Открепить</Button>
                    </Space>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Table columns={getColumns()} rowKey={'id'} dataSource={props.data}
                       size={'small'}
                       style={{width: '100%'}}
                       rowSelection={{
                           type: 'checkbox',
                           onChange: (selectedRowKeys, selectedRows) => {
                               setSelectedRows(selectedRows);
                           }
                       }} bordered scroll={{x: "max-content",}}
                />
            </Row>
            {showJoin && <JoinColumnsManager data={selectedRows} onHide={() => {
                setShowJoin(false);
                setSelectedRows([]);
                props.onRefresh();
            }}/>}
            {showSplit && <SplitPortions data={selectedRows} onHide={() => {
                setShowSplit(false);
                setSelectedRows([]);
                props.onRefresh();
            }}/>}
            {showResynth && <Resynth data={selectedRows} type={SynthTypes.EXTRA_SYNTH} onHide={() => {
                setShowResynth(false);
                setSelectedRows([]);
                props.onRefresh();
            }}
            />}
        </Modal>
    );
}

OligPick.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onRefresh: PropTypes.func.isRequired,
    // пробирки в фасовке
    data: PropTypes.array.isRequired,
    // id фасовки
    packageId: PropTypes.number.isRequired,
}

export default OligPick;
