import StageManager from "./StageManager";
import {useContext, useState} from "react";
import {
    filterOperationOptions,
    getElutionColumns, getMassResultsColumns, getPhoresisColumns, getSeatingColumns, mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "./Shared";
import {Radio} from "antd";
import QueueTable from "./QueueTable";
import {ServerOperations, Stages} from "./Operatoins";
import dayjs from "dayjs";
import {PermissionsContext} from "../../lib/PermissionsContext";

const visibleModes = {
    Waiting: 1,
    NonCompletedPhoresis: 2,
    NonCompletedElution: 3,
}

const visibleOptions = [
    {label: 'Ожидание', value: visibleModes.Waiting},
    {label: 'Незавершенный форез', value: visibleModes.NonCompletedPhoresis},
    {label: 'Незавершенная элюция', value: visibleModes.NonCompletedElution},
]

const queuePhoresisColumns = [
    {id: 1, width: 60, title: 'Очередь', dataIndex: 'queue'},
    {
        id: 2, width: 150, title: 'Начало', dataIndex: 'started_at', render: function (v) {
            return dayjs(v).format('DD.MM.YYYY HH:mm')
        }
    },
    {id: 3, width: 150, title: 'Концентрация', dataIndex: 'concentration'},
    {id: 4, width: 150, title: 'Количество ячеек', dataIndex: 'cell_count'},
    {id: 5, width: 150, title: 'Комментарий', dataIndex: 'comment'},
];


const ququeElutionColumns = [
    {id: 1, width: 60, title: 'Очередь', dataIndex: 'queue'},
    {
        id: 3, width: 150, title: 'Начало', dataIndex: 'started_at', render: function (v) {
            return dayjs(v).format('DD.MM.YYYY HH:mm')
        }
    },
    {id: 4, width: 150, title: 'Реактив', dataIndex: 'reagent'},
    {id: 5, width: 150, title: 'Комментарий', dataIndex: 'comment'},
];

export default function Paag() {

    const [visibleMode, setVisibleMode] = useState(visibleModes.Waiting);
    const [queueIds, setQueueIds] = useState(null);

    const {columns, filters} = useColumns();

    const restColumns = columns.concat(getPhoresisColumns(),
        getElutionColumns(), getSeatingColumns(), getMassResultsColumns());

    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('phoresis.edit');


    function getOperations() {
        if (visibleMode === visibleModes.Waiting) {
            return [
                [oms[ops.JOIN], oms[ops.PHORESIS], oms[ops.ELUTION], oms[ops.OE_MEASURING], oms[ops.ADD_PREFIX], oms[ops.DEFECT]],
                [oms[ops.SPLIT], null, oms[ops.SEATING], oms[ops.TO_MASS_SPECTR], null, oms[ops.NEXT_STAGE]],
            ];
        } else if (visibleMode === visibleModes.NonCompletedPhoresis) {
            return [
                [oms[ops.PHORESIS]],
            ];
        } else {
            return [
                [oms[ops.ELUTION]],
            ];
        }
    }

    function onFilterRows(queueId) {
        setQueueIds(queueId);
    }

    function getExtraFilter(onChange, disabled) {
        return <>
            <Radio.Group
                options={visibleOptions} size={'small'}
                onChange={e => {
                    setVisibleMode(e.target.value);
                    setQueueIds(null);
                    if (onChange) {
                        onChange();
                    }
                }}
                value={visibleMode}
                optionType="button"
                buttonStyle="solid"
                disabled={disabled}
            />
            {visibleMode === visibleModes.NonCompletedElution ?
                <QueueTable columns={ququeElutionColumns} operationId={ServerOperations.ELUTION}
                            stageId={Stages.ELECTROPHORESIS}
                            onChange={onFilterRows}/> : null}
            {visibleMode === visibleModes.NonCompletedPhoresis ?
                <QueueTable columns={queuePhoresisColumns} operationId={ServerOperations.PHORESIS}
                            stageId={Stages.ELECTROPHORESIS}
                            onChange={onFilterRows}/> : null}

        </>
    }

    function getQueryParams() {

        let result = [];
        if (visibleMode === visibleModes.Waiting) {
            result.push(`operations[0][id]=${ServerOperations.ELUTION}&operations[0][status]=` + filterOperationOptions.hasCompleted);
            result.push(`operations[1][id]=${ServerOperations.PHORESIS}&operations[1][status]=` + filterOperationOptions.hasCompleted);
        } else if (visibleMode === visibleModes.NonCompletedElution) {
            if (queueIds) {
                for (let id of queueIds) {
                    result.push(`queue_id[]=${id}`);
                }
            }
            result.push(`operations[0][id]=${ServerOperations.ELUTION}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
            result.push('status=0');
        } else if (visibleMode === visibleModes.NonCompletedPhoresis) {
            if (queueIds) {
                for (let id of queueIds) {
                    result.push(`queue_id[]=${id}`);
                }
            }
            result.push(`operations[0][id]=${ServerOperations.PHORESIS}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
            result.push('status=0');
        }

        return result;
    }

    function getMapFunction() {
        return mapDataFunction;
    }

    function filterNonFilledItems(items) {
        if (visibleMode === visibleModes.Phoresis) {
            return items.filter(item => item.filled > 0);
        } else {
            return items;
        }
    }

    return <StageManager
        title={'Форез'}
        currentStageId={Stages.ELECTROPHORESIS}
        operationButtons={getOperations()}
        columns={restColumns}
        mapFucntion={getMapFunction()}
        url={'/api/paag'}
        queryParams={getQueryParams()}
        filter={filters}
        dataFilter={filterNonFilledItems}
        extraFilter={getExtraFilter}
        canEdit={canEdit}
    />;

}
