import StageManager from "./StageManager";
import {useContext, useState} from "react";
import {
    getMassResultsColumns,
    mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "./Shared";
import {PermissionsContext} from "../../lib/PermissionsContext";

const DistrAndCleaning = () => {

    const [queryParams, setQueryParams] = useState([]);
    const {columns, filters} = useColumns();

    const restColumns = columns.concat(getMassResultsColumns());

    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('distribute.edit');



    function getOperations() {
        return [
            [oms[ops.JOIN], oms[ops.OE_MEASURING], null, oms[ops.TO_MASS_SPECTR], oms[ops.ADD_PREFIX], oms[ops.DEFECT]],
            [oms[ops.SPLIT], null, null, null, null, oms[ops.NEXT_STAGE]],
        ];
    }

    return <StageManager
        title={'Распределитель очистки'}
        operationButtons={getOperations()}
        columns={restColumns}
        mapFucntion={mapDataFunction}
        url={'/api/distribute'}
        queryParams={queryParams}
        filter={filters}
        canEdit={canEdit}
    />;

}

export default DistrAndCleaning;
