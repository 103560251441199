import {Button, Col, ConfigProvider, Form, Input, Modal, Row, Select, Space, Table} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import NoFutureDate from "../../../components/NoFutureDate";
import {cut} from "../../../lib/util";
import {useAsyncState, useEffectAsync} from "../../../lib/async";


const methods = [
    {id: null, value: ''},
    {id: 'Phos', value: 'Phos'},
    {id: 'NH2', value: 'NH2'},
    {id: 'Стандартный', value: 'Стандартный'},
];

/**
 * Преобразует десятичное число в произвольную систему счисления
 * Для нумерации пробирок
 * @param num
 * @returns {string}
 */
function decimalToCustomBase(num) {
    if (num < 1) {
        throw new Error("Число должно быть > 0");
    }

    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const base = 12;
    let result = '';

    while (num > 0) {
        num--;
        let remainder = num % base + 1;
        num = Math.floor(num / base);
        let letterIndex = num % 26;
        result = letters[letterIndex] + remainder + result;
        num = Math.floor(num / 26);
    }

    return result;
}

/**
 * Преобразует произвольное число в десятичное
 * Для нумерации пробирок
 * @param customBaseStr
 * @returns {number}
 */
function customBaseToDecimal(customBaseStr) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const base = 12;

    const match = customBaseStr.match(/^([A-Z]+)(\d+)$/);
    if (!match) {
        throw new Error("Неправильный формат строки", customBaseStr);
    }

    const letterPart = match[1];
    const numberPart = parseInt(match[2], 10);

    if (numberPart < 1 || numberPart > 12) {
        throw new Error("Неправильная часть номера. Должна быть между 1 и 12.");
    }

    let letterValue = 0;
    for (let i = 0; i < letterPart.length; i++) {
        letterValue = letterValue * 26 + (letters.indexOf(letterPart[i]) + 1);
    }
    return (letterValue - 1) * base + numberPart;
}

const addDecimalToCustomBase = (num, add) => {
    return decimalToCustomBase(customBaseToDecimal(num) + add);
}

const incCustomBase = (num) => {
    return addDecimalToCustomBase(num, 1);
}

const defaultSorter = (a, b) => {
    // Сначала сортировка по дате синтеза
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;

    // Затем по короткому имени устройства
    if (a.device_short_name < b.device_short_name) return -1;
    if (a.device_short_name > b.device_short_name) return 1;

    // Затем по циклу устройства
    if (a.device_cycle_name < b.device_cycle_name) return -1;
    if (a.device_cycle_name > b.device_cycle_name) return 1;

    // Затем по месту устройства
    if (a.device_place_name < b.device_place_name) return -1;
    if (a.device_place_name > b.device_place_name) return 1;

    // И, наконец, по номеру позиции устройства
    return a.device_place_position - b.device_place_position;
}

const positionSorer = (a, b) => {
    // Разделяем позицию на букву и число
    const [aLetter, aNumber] = a.position.match(/([A-Z])(\d+)/).slice(1);
    const [bLetter, bNumber] = b.position.match(/([A-Z])(\d+)/).slice(1);
    // Сравниваем буквы
    if (aLetter !== bLetter) {
        return aLetter.localeCompare(bLetter);
    }
    // Если буквы одинаковые, сравниваем числа
    return parseInt(aNumber) - parseInt(bNumber);
}

const workSequenceSorter = (a, b) => {
    // Сортировка по рабочей последовательности слева направо
    if (a.work_sequence < b.work_sequence) return -1;
    if (a.work_sequence > b.work_sequence) return 1;
    return 0;
}

const SortType = {
    None: 0,
    Default: 1,
    WorkSequence: 2,
    Position: 3,
}

const sortTypes = [
    {value: SortType.None, label: 'Без сортировки', sorter: null},
    {value: SortType.Default, label: 'По умолчанию', sorter: defaultSorter},
    {value: SortType.WorkSequence, label: 'По рабочей последовательности', sorter: workSequenceSorter},
    {value: SortType.Position, label: 'По позиции', sorter: positionSorer},
];

export function Cartridge(props) {

    const [id, setId] = useState(null);
    const [comment, setComment] = useState();
    const [started, setStarted] = useState();
    const [finished, setFinished] = useState();
    const [portionsToSend, setPortionsToSend] = useAsyncState([]);
    const [queueId, setQueueId] = useState();
    const [materials, setMaterials] = useState();
    const [data, setData] = useAsyncState(props.data);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sortType, setSortType] = useAsyncState();

    function sortData() {
        const sorter = sortTypes.find(item => item.value === sortType)?.sorter || null;
        if (sorter) {
            const newData = [...data].sort(sorter);
            setData(newData);
            setPortionsToSend(newData);
        }
    }

    function onOk() {
        const portions = portionsToSend.map(item => {
            return {
                id: item.id,
                params: {
                    started_at: started,
                    finished_at: finished,
                    comment: comment,
                    method: item.method,
                    position: item.position
                }
            }

        });
        const materialIds = (materials && materials.length && materials.map(item => item.id)) || [];
        const params = {
            materials: materialIds
        }
        if (queueId) {
            OperationsApi.editOperation(ServerOperations.GLENPACK, queueId, portions, params).then((result) => {
                props.onHide();
            });
        } else {
            OperationsApi.doOperation(ServerOperations.GLENPACK, portions, params).then((result) => {
                props.onHide();
            });
        }
    }

    function updateData(index, record) {
        const newData = portionsToSend;
        newData[index] = record;
        setPortionsToSend([...newData]);
    }

    function validate() {
        if (!started) {
            return false;
        }
        if (finished) {
            for (let i = 0; i < portionsToSend.length; i++) {
                if (!portionsToSend[i].position || !portionsToSend[i].method) {
                    return false;
                }
            }
        }
        return true;
    }

    function OKDisabled() {
        return !validate();
    }

    function autoFillPositions(startIndex = 0, startValue = 'A1') {
        if (startValue === "") {
            const index = startIndex - 1;
            if (index >= 0) {
                const prevValue = data[index].position;
                const match = prevValue.match(/^([A-Z]+)(\d+)$/);
                if (match) {
                    startValue = incCustomBase(prevValue);
                } else {
                    startValue = "A1";
                }
            } else {
                startValue = "A1";
            }
        }
        let position = customBaseToDecimal(startValue);
        const newData = [...data];
        for (let i = startIndex; i < data.length; i++) {
            newData[i].position = decimalToCustomBase(position);
            position++;
        }
        setData(newData);
        setPortionsToSend(newData);
    }

    function getColumns() {

        function renderMethod(value, record) {
            return <Select value={record.method} options={methods}
                           onChange={(value) => {
                               const index = data.findIndex(item => item.id === record.id);
                               data[index].method = value;
                               updateData(index, data[index]);
                           }}/>
        }

        function renderPosition(value, record) {
            return <Input value={record.position} onChange={(e) => {
                const index = data.findIndex(item => item.id === record.id);
                data[index].position = String(e.target.value).toUpperCase();
                updateData(index, data[index]);
            }} onBlur={(e) => {
                const index = data.findIndex(item => item.id === record.id);
                autoFillPositions(index, e.target.value);
            }}/>
        }

        let columns = getDefaultOperationColumns();

        // удаляем старые mod3 и mod5
        columns = columns.filter(item => item.dataIndex !== 'modification_5' && item.dataIndex !== 'modification_3');

        // новые mod3 и mod5 от рабочей последовательности
        const new_mods_colums = [
            {
                title: "5'",
                dataIndex: 'modification_5_work',
                key: 'modification_5_work',
                width: 60, render: v => cut(v, 10)
            },
            {
                title: "3'",
                dataIndex: 'modification_3_work',
                key: 'modification_3_work',
                width: 60, render: v => cut(v, 10)
            },
        ];

        // вставляем новые колонки после колонки с индексом 6 (olig_sequence)
        columns.splice(6, 0, ...new_mods_colums);

        // добавить еще колонок
        columns.push({
            title: 'Метод',
            width: 100,
            dataIndex: 'method',
            render: (value, record) => renderMethod(value, record)
        });

        columns.push({
            title: () => {
                return <Space>
                    <Button size={'small'} onClick={() => {
                        const newData = [...data];
                        for (let i = 0; i < newData.length; i++) {
                            newData[i].position = '';
                        }
                        setData(newData);
                        setPortionsToSend(newData);
                        setTimeout(() => {
                            autoFillPositions();
                        }, 100);
                    }}>Позиция</Button>
                </Space>
            },
            dataIndex: 'position',
            width: 100,
            render: (value, record) => renderPosition(value, record)
        });

        return columns;
    }

    useEffectAsync(async () => {
        if (props.data[0].glenpack?.pivot?.params) {
            if (!props.data[0].glenpack.pivot.finished_at) {
                setId(props.data[0].glenpack.pivot.id);
                setStarted(props.data[0].glenpack.pivot.started_at);
                setComment(props.data[0].glenpack.pivot.params.comment);
                setQueueId(props.data[0].glenpack.pivot.portion_to_operation_id);
                setMaterials(props.data[0].glenpack.params.materials);
                const newData = props.data.map(item => {
                    return {
                        ...item,
                        method: item.glenpack?.pivot?.params?.method,
                        position: item.glenpack?.pivot?.params?.position
                    }
                });
                await setData(newData);
                await setPortionsToSend(newData);
                await setSortType(SortType.Position);
            }
        } else {
            await setData(props.data);
            await setPortionsToSend(props.data);
            await setSortType(SortType.Default);
            autoFillPositions();
        }
    }, [props.data])

    useEffect(() => {
        sortData();
    }, [sortType]);

    return <Modal
        title="Картриджная очистка"
        open={true}
        width={'95%'}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={12}>
                Фактическое время и дата начала:&nbsp;
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setStarted(value)}/>
                </ConfigProvider>
            </Col>
            <Col span={12}>
                Фактическое время и дата завершения:&nbsp;
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'}
                                  value={finished ? dayjs(finished) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setFinished(value)}/>
                </ConfigProvider>
            </Col>
        </Row>
        <Row style={{marginTop: 20}}>
            <Col span={12}>
                <Form layout="inline" style={{width: '90%'}}>
                    <Form.Item label={'Комментарий'} style={{width: '100%'}}>
                        <Input style={{width: '100%'}} value={comment} onChange={(e) => setComment(e.target.value)}/>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={12}>
                <Form layout="inline">
                    <Form.Item label={'Установить всем'}>
                        <Select placeholder="Метод" style={{width: 120}} options={methods}
                                onChange={(value) => {
                                    let newData = [...portionsToSend];
                                    newData = newData.map(item => {
                                        if (selectedRowKeys.includes(item.id)) {
                                            return {...item, method: value}
                                        }
                                        return {...item}
                                    });
                                    setPortionsToSend(newData);
                                    setData(newData);
                                }}/>
                    </Form.Item>
                    <Form.Item label={'Сортировать'}>
                        <Select value={sortType} options={sortTypes} onChange={(value) => {
                            setSortType(value);
                        }}/>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
            <Col span={12}>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey="id" dataSource={data} columns={getColumns()}
                       siz={'small'}
                       rowSelection={{
                           selectedRowKeys,
                           onChange: (selectedRowKeys) => {
                               setSelectedRowKeys(selectedRowKeys);
                           }
                       }}/>
            </Col>
        </Row>
    </Modal>
}
