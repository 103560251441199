import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Input, Popconfirm, Radio, Row, Table} from "antd";
import {DeleteOutlined, EditOutlined, FileAddOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {api, buildQuery} from "../../lib/util";
import PageHeader from "../../components/mock/PageHeader";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {PermissionsContext} from "../../lib/PermissionsContext";

const {Search} = Input;

const statusOptions = [
    {value: 1, label: 'В ожидании'},
    {value: 2, label: 'В производстве'},
    {value: 3, label: 'Завершен'},
    {value: 4, label: 'Отменен'},
];

const OrderListData = (props) => {
    return (
        <Table
            columns={props.columns}
            rowKey="id"
            size={'small'}
            dataSource={props.data}
            scroll={{y: '100%'}}
            pagination={{pageSize: props.pageSize, total: props.total, pageSizeOptions: []}}
            loading={props.loading}
            onChange={props.onChange}
            style={{marginTop: 20}}
        />
    );
};

OrderListData.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
};

const OrderList = () => {
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [statusFilter, setStatusFilter] = useState(1);
    const [scroll, setScroll] = useState(0);

    const ref = useRef();

    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('orders.edit');
    const canView = hasPermission('orders.view');


    useEffect(() => {
        update();
    }, [statusFilter, search]);

    useEffect(() => {
        updateScroll();
    }, [data]);

    const getColumns = () => {
        const columns = [
            {
                title: '№', dataIndex: 'order_number', key: 'order_number', width: 50, fixed: 'left',
            },
            {
                title: 'Дата создания',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                ellipsis: true,
                width: '150px',
                render: (value) => dayjs(value).utc().format('LL'),
            },
            {
                title: 'Заказчик',
                dataIndex: 'organization',
                key: 'organization',
                sorter: true,
                width: '200px',
                render: (value, row) => row.user?.organization,
            },
            {
                title: 'Приоритет',
                dataIndex: 'priority_text',
                key: 'priority_text',
                sorter: true,
                width: '150px',
                ellipsis: false,
            },
            {
                title: 'Тип заказа',
                dataIndex: 'order_type_id',
                key: 'order_type_id',
                sorter: true,
                width: '150px',
                render: (text, record) => record.type.name,
            },
        ];
        columns.push({
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                render: (text, record) => (
                    <div>
                        <Link to={`/order/edit/${record.id}`}>
                            <Button size="small" shape="circle" className={'edit-btn'}><EditOutlined/></Button>
                        </Link>
                        {canEdit && <Popconfirm
                            title="Вы уверены что хотите удалить заказ?"
                            okText="Да"
                            onConfirm={() => deleteOrder(record.id)}
                            cancelText="Нет"
                        >
                            {(statusFilter === 1 || statusFilter === 4) && <Button
                                size="small"
                                shape="circle"
                                style={{marginLeft: 5}}
                                className={'del-btn'}
                            >
                                <DeleteOutlined/>
                            </Button>}
                        </Popconfirm>}
                    </div>
                ),
                width: '85px',
            }
        );

        return columns;
    };

    const deleteOrder = (id) => {
        setLoading(true);
        api.postJSON(`/api/order/delete/${id}`).then(() => {
            handleTableChange({}, {}, {});
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current - 1);
        load({
            page: pagination.current,
            search: search,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const handleSearch = (value) => {
        setSearch(value);
    };

    const update = () => {
        load({pagination});
    };

    const load = (params = {}) => {
        if (statusFilter) {
            params.status = statusFilter;
        }
        if (search) {
            params.search = search;
        }
        const query = buildQuery(params);
        setLoading(true);
        api.getJSON(`/api/order?${query}`).then(response => {
            setData(response.data);
            setTotal(response.total);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const updateScroll = () => {
        if (ref.current) {
            const posOfTable = ref.current.getBoundingClientRect();
            const clientHeight = document.documentElement.clientHeight;
            const offsetY = 116;
            setScroll(clientHeight - posOfTable.top - offsetY);
        } else {
            console.log('ref.current is null');
        }
    };

    return (
        <React.Fragment>
            <PageHeader title="Заказы"/>
            <div style={{background: '#fff', padding: 24, minHeight: 280}}>
                <Row>
                    <Col span={24}>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={12}>
                                <Search placeholder="поиск" onSearch={handleSearch}/>
                            </Col>
                            <Col span={12}>
                                {canEdit && <Button style={{float: 'right'}}>
                                    <Link to="/order/create">
                                        <FileAddOutlined/> Добавить
                                    </Link>
                                </Button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Radio.Group
                                    defaultValue="all"
                                    buttonStyle="solid"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    size={'small'}
                                >
                                    {statusOptions.map((item, index) => (
                                        <Radio.Button key={index} value={item.value}>{item.label}</Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} ref={ref}>
                        <OrderListData
                            data={data}
                            scroll={scroll}
                            columns={getColumns()}
                            total={total}
                            loading={loading}
                            onChange={handleTableChange}
                            pageSize={pageSize}
                        />
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default OrderList;
