import {createContext, useEffect, useState} from "react";
import api from "./util";

export const PermissionsContext = createContext(null);

const PermissionsProvider = ({children}) => {

    const [menuPermissions, setMenuPermissions] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const loadPermissions = () => {
        return api.getJSON('/api/user/me')
            .then(function (response) {
                setMenuPermissions(response.menu_items);
                setPermissions(response.permissions);
                // если есть роль с id = 1, то это админ
                setIsAdmin(response.roles.some(role => role.id === 1));
            });
    }

    const loadAllPermissions = () => {
        api.getJSON('/api/permission').then(response => {
            setAllPermissions(response);
        })
    }

    const getMenuPermission = (url) => {
        return menuPermissions.find(permission => (url.startsWith(permission.url) && permission.url !== '/'));
    }

    const hasPermission = (code) => {
        // if (isAdmin) {
        //     return true;
        // }
        if (Array.isArray(code)) {
            return code.some(c => !!permissions.find(permission => permission.code === c));
        }
        return !!permissions.find(permission => permission.code === code);
    }

    const canView = (url) => {
        // нормализация url для совместимости со старыми версиями
        // например может не указано .view или наоборот - указано.
        let code = url;
        if (!code.endsWith('.view')) {
            code += '.view';
        }
        if (code.startsWith('/')) {
            code = code.substring(1);
        }
        return !!getMenuPermission(url) || hasPermission(code);
    }

    const canEdit = (code) => {
        // нормализовать код для совместимости со старыми версиями
        // например может не указано .edit или наоборот - указано.
        if (!code.endsWith('.edit')) {
            code += '.edit';
        }
        if (code.startsWith('/')) {
            code = code.substring(1);
        }
        return hasPermission(code);
    }

    const enablePermission = (code) => {
        let permission = allPermissions.find(permission => permission.code === code);
        if (permission) {
            permissions.push(permission);
            setPermissions([...permissions]);
        }
    }

    const disablePermission = (code) => {
        let permission = permissions.find(permission => permission.code === code);
        if (permission) {
            permissions.splice(permissions.indexOf(permission), 1);
            setPermissions([...permissions]);
        }
    }

    useEffect(() => {
        loadAllPermissions();
        loadPermissions();
    }, []);


    return <PermissionsContext.Provider value={{
        isAdmin, canView, canEdit, hasPermission, allPermissions, permissions, enablePermission, disablePermission
    }}>
        {children}
    </PermissionsContext.Provider>;
}

export default PermissionsProvider;
