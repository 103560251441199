import {useEffect, useState} from "react";

export const useAsyncState = (initialState) => {
  const [state, setState] = useState(initialState);

  const asyncSetState = (value) => {
    return new Promise((resolve) => {
      setState(value);
      setState((current) => {
        resolve(current);
        return current;
      });
    });
  };

  return [state, asyncSetState];
};

export const useEffectAsync = (asyncFunction, deps) => {
    useEffect(() => {
        asyncFunction();
    }, deps);
}
