import React, {useContext} from 'react';
import {Button, Drawer, Layout, Menu} from "antd"
import {Route, Switch} from "react-router-dom";
import {Helmet} from "react-helmet";
import {menuItems, routes} from "./routes";
import {PermissionsContext} from "../lib/PermissionsContext";

const Index = (props) => {

    const {history} = props;
    const selectedKeys = history.location.pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const [visible, setVisible] = React.useState(false);
    const {hasPermission} = useContext(PermissionsContext);

    const renderMenuItem = (item) => {
        const label = item.render ? item.render() : <a href={'#'} onClick={(e) => {
            hideMenu();
            history.push(item.url);
            e.preventDefault();
        }}>{item.label}</a>;
        return {
            key: item.id, label, url: item.url,
        }
    }

    const getMenuItems = () => {
        let items = [...menuItems];

        // включить сортировку по избранному если приспичит
        // const favoritesManager = new FavoritesManager();
        // const favorites = favoritesManager.getFavorites();
        // items = items.sort((a, b) => {
        //     const aIndex = favorites.findIndex(fav => fav.path === a.url);
        //     const bIndex = favorites.findIndex(fav => fav.path === b.url);
        //     if (aIndex !== -1 && bIndex !== -1) {
        //         return aIndex - bIndex;
        //     }
        //     if (aIndex !== -1) {
        //         return -1;
        //     }
        //     if (bIndex !== -1) {
        //         return 1;
        //     }
        //     return 0;
        // });

        // удалить id: main из items
        const index = items.findIndex(item => item.id === 'main');
        if (index !== -1) {
            items.splice(index, 1);
        }
        // вставить main в начало
        items.unshift(menuItems.find(item => item.id === 'main'));

        return items.map(item => {
            if (hasPermission(item.permission) || item.id === 'logout') {
                return renderMenuItem(item);
            }
        });
    }

    const getRoutes = () => {

        const sortByUrl = (objectList) => {
            return objectList.sort((a, b) => {
                if (a.props.path < b.props.path) {
                    return 1;
                }
                if (a.props.path > b.props.path) {
                    return -1;
                }
                return 0;
            });
        }

        let keyIndex = 0;
        const m = menuItems.map(item => {
            if (item.permission) {
                if (hasPermission(item.permission)) {
                    return <Route key={keyIndex++} breadcrumbName={item.breadcrumbName} path={`${item.url}`}
                                  component={item.component}/>
                } else {
                    return null;
                }
            } else {
                // если нет permission, то показываем всегда
                return <Route key={keyIndex++} breadcrumbName={item.breadcrumbName} path={`${item.url}`}
                              component={item.component}/>
            }
        });

        const r = routes.map(item => {
            return <Route key={keyIndex++} breadcrumbName={item.breadcrumbName} path={`${item.url}`}
                          component={item.component}/>;
        });

        const result = sortByUrl([...r, ...m].filter(item => item !== null));
        return result;
    }

    const hideMenu = () => {
        setVisible(false);
    }

    const getPageTitle = () => {
        const selectedMenuItem = menuItems.find(item => item.url === history.location.pathname);
        if (selectedMenuItem) {
            return selectedMenuItem.label;
        }
        const selectedRoute = routes.find(route => route.url === history.location.pathname);
        if (selectedRoute) {
            return selectedRoute.breadcrumbName;
        }
        return 'Genterra';
    };

    return <Layout className="layout" style={{minHeight: "100vh"}}>
        <Helmet>
            <title>{getPageTitle()}</title>
        </Helmet>
        <Button onClick={() => setVisible(true)}
                style={{
                    width: '44px', height: '38px', padding: '0', position: 'absolute', border: 0,
                    left: '8px', top: '8px'
                }}>
            <img src={'/images/logo.png'} width={36} height={24} alt={'menu'} style={{position: 'relative', top: 3}}/>
        </Button>

        <Drawer title={"Меню"} placement="left" closable={true} onClose={hideMenu} open={visible}>
            <Menu theme="light" defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} mode="vertical"
                  style={{width: 256}} items={getMenuItems()}/>
        </Drawer>
        <div style={{background: '#f5f5f5', minHeight: '100vh'}}>
            <Switch>
                {getRoutes()}
            </Switch>
        </div>
    </Layout>
}

export default Index;
