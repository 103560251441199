import StageManager from "./StageManager";
import {useContext, useState} from "react";
import {Radio} from "antd";
import {
    clickQueueColumns,
    evaporationQueueColumns,
    filterOperationOptions,
    getClickColumns,
    getEvaporationColumns, getMassResultsColumns, getSeatingColumns,
    mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "./Shared";
import QueueTable from "./QueueTable";
import {ServerOperations, Stages} from "./Operatoins";
import {PermissionsContext} from "../../lib/PermissionsContext";

const visibleModes = {
    Waiting: 1,
    NonCompletedClick: 2,
    NonCompletedUpar: 3,
}

const visibleOptions = [
    {label: 'Ожидание', value: visibleModes.Waiting},
    {label: 'Незавершенный клик', value: visibleModes.NonCompletedClick},
    {label: 'Незавершенное упаривание', value: visibleModes.NonCompletedUpar},
]

export default function Click() {

    const [visibleMode, setVisibleMode] = useState(visibleModes.Waiting);
    const [queueIds, setQueueIds] = useState(null);
    const {columns, filters} = useColumns();
    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('click.edit');

    const restColumns = columns.concat(getClickColumns(), getEvaporationColumns(), getSeatingColumns(),
        getMassResultsColumns()
    );

    function getOperations() {
        if (visibleMode === visibleModes.Waiting) {
            return [
                [oms[ops.JOIN], oms[ops.CLICK], oms[ops.EVAPORATION], oms[ops.OE_MEASURING], oms[ops.ADD_PREFIX], oms[ops.DEFECT]],
                [oms[ops.SPLIT], null, oms[ops.SEATING], oms[ops.TO_MASS_SPECTR], null, oms[ops.NEXT_STAGE]],
            ];
        }
        if (visibleMode === visibleModes.NonCompletedClick) {
            return [
                [oms[ops.CLICK]],
            ];
        }
        if (visibleMode === visibleModes.NonCompletedUpar) {
            return [
                [oms[ops.EVAPORATION_COMPLETE]],
            ];
        }
        return null;
    }

    function getQueryParams() {
        let result = [];
        if (queueIds) {
            for (let id of queueIds) {
                result.push(`queue_id[]=${id}`);
            }
        }
        if (visibleMode === visibleModes.Waiting) {
            result.push(`operations[0][id]=${ServerOperations.CLICK}&operations[0][status]=` + filterOperationOptions.hasCompleted);
            result.push(`operations[1][id]=${ServerOperations.EVAPORATION}&operations[1][status]=` + filterOperationOptions.hasCompleted);
        } else if (visibleMode === visibleModes.NonCompletedClick) {
            result.push(`operations[0][id]=${ServerOperations.CLICK}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
        } else if (visibleMode === visibleModes.NonCompletedUpar) {
            result.push(`operations[0][id]=${ServerOperations.EVAPORATION}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
        }
        return result;
    }

    function onFilterRows(queueId) {
        setQueueIds(queueId);
    }

    function getExtraFilter(onChange, disabled) {
        return <>
            <Radio.Group
                options={visibleOptions} size={'small'}
                onChange={e => {
                    setVisibleMode(e.target.value);
                    setQueueIds(null);
                    if (onChange) {
                        onChange();
                    }
                }}
                value={visibleMode}
                optionType="button"
                buttonStyle="solid"
                disabled={disabled}
            />
            {visibleMode === visibleModes.NonCompletedClick ?
                <QueueTable columns={clickQueueColumns} operationId={ServerOperations.CLICK}
                            stageId={Stages.CLICK} onChange={onFilterRows}/> : null}

            {visibleMode === visibleModes.NonCompletedUpar ?
                <QueueTable columns={evaporationQueueColumns} operationId={ServerOperations.EVAPORATION}
                            stageId={Stages.CLICK} onChange={onFilterRows}/> : null}
        </>
    }

    return <StageManager
        title={'Клик'}
        currentStageId={Stages.CLICK}
        operationButtons={getOperations()}
        columns={restColumns}
        mapFucntion={mapDataFunction}
        url={'/api/conjunction'}
        queryParams={getQueryParams()}
        filter={filters}
        extraFilter={getExtraFilter}
        canEdit={canEdit}
    />;

}
