import React, {useContext, useEffect} from 'react';
import {Row, Col, Button, Input, Form, Skeleton, Space} from "antd"

import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import api from "../../lib/util";
import {withRouter} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import Crud from "../../lib/Crud";
import {useForm} from "antd/lib/form/Form";
import {NotifySuccess} from "../../lib/notify";
import PageHeader from "../../components/mock/PageHeader";
import {PermissionsContext} from "../../lib/PermissionsContext";


const layout = {
    labelCol: {span: 7},
    wrapperCol: {span: 17},
};

const ClientEdit = (props) => {

    const [formInstance] = useForm();
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState({});
    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('client.edit');

    const load = () => {
        const id = props.match.params.id;
        if (id) {
            setLoading(true);
            api.getJSON('/api/client/edit/' + id).then((res) => {
                setItem(res);
                formInstance.setFieldsValue(res);
                setLoading(false);
            });
        }
    }

    const save = () => {
        formInstance.validateFields().then((values) => {
            const url = item.id ? '/api/client/update/' + item.id : '/api/client/store';
            values = {...values, contacts: item.contacts};
            api.postJSON(url, values).then((res) => {
                    if (res.id) {
                        setItem({...item, id: res.id});
                        NotifySuccess('Запись сохранена');
                    }
                }
            ).catch((errors) => {
                if (errors.errors) {
                    const remappedErrors = Object.keys(errors.errors).map((key) => {
                        return {
                            name: key,
                            errors: errors.errors[key]
                        }
                    });
                    formInstance.setFields(remappedErrors);
                }
            });
        });
    }

    const close = () => {
        props.history.push('/client');
    }

    useEffect(() => {
        load();
    }, []);

    let title = '';
    if (hasPermission('client.edit')) {
        title = item?.id ? 'Редактирование клиента' : 'Создание клиента';
    } else {
        title = 'Просмотр клиента';
    }

    return (
        <React.Fragment>
            <PageHeader title={title}/>
            <Skeleton loading={loading}>
                <Form form={formInstance}
                      {...layout}
                      preserve={false}
                      initialValues={{remember: true}}
                      style={{background: '#fff', padding: 24, minHeight: 280}}
                      onFinish={save}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Организация"
                                name="organization"
                                initialValue={item?.organization}
                                rules={[{required: true, message: 'Поле "Организация" обязательно'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Адрес"
                                name="address"
                                initialValue={item?.address}
                                rules={[{required: true, message: 'Поле "Адрес" обязательно'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Телефон"
                                name="phone"
                                initialValue={item?.phone}
                                rules={[{required: true, message: 'Поле "Телефон" обязательно'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                initialValue={item?.email}
                                rules={[
                                    {required: true, message: 'Поле "E-mail" обязательно'},
                                    {type: 'email', message: 'Некорректный E-mail'}
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Комментарий"
                                name="comment"
                                initialValue={item?.comment}
                            >
                                <TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Space direction={'horizontal'}>
                            {canEdit && <Button type="primary" icon={<SaveOutlined/>} htmlType="submit">Сохранить</Button>}
                            <Button icon={<CloseOutlined/>} onClick={close}>Закрыть</Button>
                        </Space>
                    </Row>
                </Form>
                {item && item.id && <Crud title="Контактные лица/представители"
                                          canEdit={canEdit}
                                          editorWidth={'800px'}
                                          createTitle={'Создание контактного лица/представителя'}
                                          editTitle={'Редактирование контактного лица/представителя'}
                                          url={`/api/user/${item.id}/contacts`}
                                          onMapLoad={(d) => d.data}
                                          onChange={(values) => {
                                              setItem({...item, contacts: values});
                                          }}
                                          viewConfig={[
                                              {title: 'ФИО', dataIndex: 'name', key: 'name'},
                                              {title: 'Должность', dataIndex: 'position', key: 'position'},
                                              {title: 'Подразделение', dataIndex: 'branch', key: 'branch'},
                                              {title: 'Телефон', dataIndex: 'phone', key: 'phone'},
                                              {title: 'E-mail', dataIndex: 'email', key: 'email'},
                                              {title: 'Комментарий', dataIndex: 'comment', key: 'comments'},
                                          ]}
                                          editConfig={[
                                              {
                                                  name: 'name',
                                                  title: 'ФИО',
                                                  type: 'text',
                                                  autoFocus: true,
                                                  rules: [{required: true, message: 'Поле "ФИО" обязательно'}]
                                              },
                                              {
                                                  name: 'position',
                                                  title: 'Должность',
                                                  type: 'text',
                                                  rules: [{required: false}]
                                              },
                                              {
                                                  name: 'branch',
                                                  title: 'Подразделение',
                                                  type: 'text',
                                                  rules: [{required: false}]
                                              },
                                              {
                                                  name: 'phone',
                                                  title: 'Телефон',
                                                  type: 'text',
                                                  rules: [{required: true, message: 'Поле "Телефон" обязательно'}]
                                              },
                                              {
                                                  name: 'email',
                                                  title: 'E-mail',
                                                  type: 'text',
                                                  rules: [
                                                      {required: true, message: 'Поле "E-mail" обязательно'},
                                                      {type: 'email', message: 'Некорректный E-mail'}
                                                  ]
                                              },
                                              {
                                                  name: 'comment',
                                                  title: 'Комментарий',
                                                  type: 'text',
                                              },
                                          ]}
                />}
            </Skeleton>
        </React.Fragment>
    );
}

export default withRouter(ClientEdit);

