import React, {useContext} from 'react';
import {CloseOutlined, SettingOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import {FavoritesContext} from "../../lib/FavoritesContext";
import {NotifyInfo, NotifySuccess} from "../../lib/notify";
import {Button} from "antd";
import {useHistory} from "react-router";
import PermissionsModal from "../PermissionsModal";

const FavoriteIcon = ({name, path}) => {

    const {addFavorite, removeFavorite, inFavorites} = useContext(FavoritesContext);

    const handleToggleFavorite = () => {
        if (inFavorites({path})) {
            removeFavorite({name, path});
            NotifyInfo('Раздел ' + name + ' удален из избранного');
        } else {
            addFavorite({name, path});
            NotifySuccess('Раздел ' + name + ' добавлен в избранное');
        }
    };

    return (
        <>
            {inFavorites({path}) ? (
                <StarFilled
                    style={{color: '#f0ad4e', fontSize: '20px'}}
                    onClick={handleToggleFavorite}
                />
            ) : (
                <StarOutlined
                    style={{color: '#f0ad4e', fontSize: '20px'}}
                    onClick={handleToggleFavorite}
                />
            )}
        </>
    );
};

const PageHeader = ({title, showFavorite, showCloseBtn}) => {

    let path = window.location.pathname;
    path = '/' + path.split('/')[1];

    const history = useHistory();
    const [showPermissions, setShowPermissions] = React.useState(false);

    return (
        <>
            <div className="page-header" style={{position: 'relative', left: 52, top: 1}}>
                <div className="ant-page-header-heading">
                    <div className=""
                         style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: 500}}>
                            <span className="ant-page-header-heading-title"
                                  style={{fontSize: 20, fontWeight: 400}}
                                  title={title}>{title}</span>
                            {showFavorite && <FavoriteIcon name={title} path={path}/>}
                        </div>
                        <div>
                            <h2 style={{position: 'relative', left: -263, top: -13, fontWeight: 400}}>
                                <span style={{color: "#62ad00", textTransform: "uppercase"}}>Oligo</span>
                                <span style={{color: "#f0ad4e", textTransform: "uppercase"}}>Tracker</span>
                            </h2>
                        </div>
                        <div>
                            <div style={{position: "relative", right: 50, width: 40, height: 50}}>
                                {/*<Button onClick={() => setShowPermissions(true)} icon={<SettingOutlined/>}/>*/}
                                {showCloseBtn &&
                                    <Button className={'close-btn'} icon={<CloseOutlined/>} onClick={() => {
                                        history.push('/main');
                                    }}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PermissionsModal onClose={() => setShowPermissions(false)} visible={showPermissions}/>
        </>);
}

PageHeader.defaultProps = {
    title: 'Заголовок',
    showFavorite: true,
    showCloseBtn: true,
};

export default PageHeader;

